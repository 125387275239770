import React, {
    SetStateAction,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { Project } from "../helpers/Projects";

interface ExpectedState {
    title: string;
    user: {
        name: string;
        email: string;
    };
    previousPage: {
        name: string
        link: string
    } | null
}

interface AppState {
    appState: ExpectedState;
    setAppState: (
        newState: Partial<ExpectedState> | SetStateAction<ExpectedState>,
    ) => void;
}

const AppContext = createContext<AppState | null>(null);

function useAppState(): AppState {
    return useContext(AppContext) as unknown as AppState;
}

function AppProvider({ children }) {
    //@ts-ignore
    const [state, setState] = useState<ExpectedState>(window.state);

    const setAppState = useCallback(
        (newState: Partial<ExpectedState> | SetStateAction<ExpectedState>) => {
            console.log(newState)
            if (typeof newState === "function") {
                setState(newState);
            } else {
                setState((prevState) => ({ ...prevState, ...newState }));
            }
        },
        [],
    );

    return (
        <AppContext.Provider value={{ appState: state, setAppState }}>
            {children}
        </AppContext.Provider>
    );
}

export { AppProvider, useAppState };
