import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import SiteNew from "../components/SiteNew";
import Emails from "../components/Emails";
import Forms from "../components/Forms";
import Events from "../components/Events";
import EventNew from "../components/EventNew";
import EventEdit from "../components/EventEdit";
import Projects from "../components/Projects";
import { useAppState } from "../contexts/AppContext";
import { useForm } from "react-hook-form";
import Auth from "../helpers/Auth";


export default function Login() {
    const { appState, setAppState } = useAppState()

    const { register, handleSubmit } = useForm();

    const onSubmit = handleSubmit((values) => {
        Auth.login(values).then((data) => {
            localStorage.setItem('token', data.token)
            
            setAppState({
                user: data.user
            })
        });
    });

    return (
        <div
            style={{ minHeight: "100vh" }}
            className="uk-flex uk-flex-middle uk-flex-center"
            data-uk-height-viewport="expand: true"
        >
            <form onSubmit={onSubmit} className="uk-card uk-card-default uk-width-1-4">
                <div className="uk-card-header uk-text-center">
                    <h3>{appState.title}</h3>
                </div>
                <div className="uk-card-body">
                    <div className="uk-margin">
                        <input className="uk-input" {...register("email")} type="email" placeholder="Email" />
                    </div>

                    <div className="uk-margin">
                        <input className="uk-input" {...register("password")} type="password" placeholder="Password" />
                    </div>

                    <div className="uk-margin uk-text-center">
                        <button type="submit" className="uk-button uk-button-primary">Login</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
