import React, { useEffect, useState } from "react";
import { useAppState } from "../contexts/AppContext";
import ProjectList from "./ProjectList";
import { useParams } from "react-router-dom";
import Stories, { Story, StoryStatus } from "../helpers/Stories";
import BoardColumn from "./BoardColumn";
import ProjectStoryStatuses from "../helpers/ProjectStoryStatuses";
import { useProject } from "../contexts/ProjectContext";
import Loading from "./Loading";

export default function Board() {
    const {statuses, setStatuses} = useProject()
    const { alias } = useParams();
    const [stories, setStories] = useState<Record<StoryStatus,Story[]>>();

    useEffect(() => {
        Stories.indexByStatus(alias).then((stories) => {
            setStories(stories);
        });
    }, [setStories]);

    useEffect(() => {
        if(statuses === null){
            ProjectStoryStatuses.index(alias).then((statuses) => {
                setStatuses(statuses);
            });
        }
    }, [])

    useEffect(() => {
        console.log(stories)
    }, [stories])

    if(!stories){
        return <Loading/>
    }

    return (
        <div style={{height: '100%'}}>
            <h1>Board</h1>

            <div className="kanban-grid uk-width-1">
                {statuses ? statuses.map((status) => (
                    <BoardColumn name={status.name} statusId={status.id} stories={stories} setStories={setStories}/>
                )): null}
            </div>
        </div>
    );
}
