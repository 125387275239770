import Requester from "./Requester";

export function login(data){
    const requester = new Requester();

    return requester.post(requester.getUrl(`api/login`), data)
}

export default {
    login,
}