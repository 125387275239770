import React, { useEffect, useState } from "react";
import FormList from "./FormList";

export default function Forms() {
    return (
        <div>
            <h1>Forms</h1>
            <FormList />
        </div>
    );
}
