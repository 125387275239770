import Requester from "./Requester";

export interface Event {
    id: number
    user_id: number
    name: string
    start_at: string
    end_at: string
    created_at: string
    updated_at: string
}

export function index(){
    const requester = new Requester();

    return requester.get(requester.getUrl("api/events"))
}

export function show(id){
    const requester = new Requester();

    return requester.get(requester.getUrl(`api/events/${id}`))
}

export function create(data){
    const requester = new Requester();

    return requester.post(requester.getUrl(`api/events`), data)
}

export default {
    index,
    create,
    show,
}