import React, { useEffect, useState } from "react";
import Events, { Event } from "../helpers/Events";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Button from "./Button";

export default function EventList() {
    const [events, setEvents] = useState<Event[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        Events.index().then((forms) => {
            setEvents(forms);
        });
    }, [setEvents]);

    const goToEdit = (id) => {
        navigate(`/events/${id}`);
    };

    return (
        <div>
            <table className="uk-table uk-table-striped">
                <thead>
                    <tr>
                        <th>Event</th>
                        <th>Dates</th>
                        <th className="uk-text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {events
                        ? events.map((event) => (
                              <tr>
                                  <td>{event.name}</td>
                                  <td>
                                      {dayjs(event.start_at).format("MMM DD")} -{" "}
                                      {dayjs(event.end_at).format("MMM DD")},{" "}
                                      {dayjs(event.start_at).format("YYYY")}
                                  </td>
                                  <td className="uk-text-right">
                                      <Button
                                          onClick={() => {
                                              goToEdit(event.id);
                                          }}
                                      >
                                          Form
                                      </Button>
                                      <Button
                                          onClick={() => {
                                              goToEdit(event.id);
                                          }}
                                      >
                                          Edit
                                      </Button>
                                  </td>
                              </tr>
                          ))
                        : null}
                </tbody>
            </table>
        </div>
    );
}
