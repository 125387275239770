import React from "react";
import { useAppState } from "../contexts/AppContext";
import ProjectList from "./ProjectList";
import Loading from "./Loading";

export default function Projects() {
    const { appState } = useAppState();

    return (
        <div>
            <h1>Projects</h1>

            <ProjectList />
        </div>
    );
}
