import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Stories, { Story, StoryStatus } from "../helpers/Stories";
import { useAppState } from "../contexts/AppContext";
import Loading from "./Loading";
import { ReactSortable } from "react-sortablejs";
import { useProject } from "../contexts/ProjectContext";

export default function Backlog() {
    const {setAppState} = useAppState()
    const {project, setProject} = useProject()
    const { alias } = useParams();
    const [stories, setStories] = useState<Story[]>();

    useEffect(() => {
        Stories.index(alias).then((stories) => {
            setStories(stories);

            if(stories[0].project){
                setProject(stories[0].project)
            }
            
        });
    }, [setStories]);

    const handleNavigateToStory = (story) => {
        setAppState({
            previousPage: {
                name: 'Backlog',
                link: `/projects/${story.project.shortName.toLocaleLowerCase()}/board`
            }
        })
    }

    const reorderBacklog = (stories: Story[]) => {
        if(!project){
            return
        }

        const data = {};
        stories.forEach((story, index) => {
            story.order = index;
            data[story.id] = index;
        });

        setStories((prev) => {
            if(!prev){
                return stories
            }

            const prevData = {};
            let shouldUpdate = false;

            prev.forEach((block, index) => {
                prevData[block.id] = index;

                if (prevData[block.id] !== data[block.id]) {
                    shouldUpdate = true;
                }
            });
            
            if (shouldUpdate) {
                Stories.reorder(project.shortName, data).then(() => { });
                return stories;
            } else {
                return prev;
            }
        });
    };


    if(!stories || !project){
        return <Loading/>
    }

    return (
        <div style={{height: '100%'}}>
            <h1>Backlog</h1>

            <ReactSortable
                list={stories}
                setList={reorderBacklog}
                className="uk-width-1 border-y"
            >
                {stories ? stories.map((story) => (
                        <div className="uk-width-1 uk-flex uk-flex-between uk-flex-middle uk-padding-small">
                            <div className="space-x">
                                <span className="uk-label">{story.fullId}</span>
                                <Link onClick={() => {
                                    handleNavigateToStory(story)
                                }} to={`/stories/${story.fullId}`}>{story.name}</Link>
                            </div>

                            <div className="space-x">
                                <span className="uk-label">{story.status.name}</span>
                                <span className="uk-label">{story.estimates} SP</span>
                            </div>
                            
                        </div>
                )) : null}
            </ReactSortable>
        </div>
    );
}
