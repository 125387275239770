import React, { useEffect, useState } from "react";
import { useAppState } from "../contexts/AppContext";
import ProjectList from "./ProjectList";
import { useParams } from "react-router-dom";
import Stories, { Story, StoryStatus } from "../helpers/Stories";
import BoardColumn from "./BoardColumn";
import ProjectStoryStatuses, { ProjectStoryStatus } from "../helpers/ProjectStoryStatuses";
import { useProject } from "../contexts/ProjectContext";

export default function Loading() {
    return (
        <div className="uk-flex uk-flex-column uk-flex-middle uk-flex-center" style={{height: '100%'}}>
            <span data-uk-spinner="ratio:2"></span>
            <span className="uk-h3">Loading</span>
        </div>
    );
}
