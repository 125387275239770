import Requester from "./Requester";

export interface Email {
    email: string
    status: number
}

export function index(){
    const requester = new Requester();

    return requester.get(requester.getUrl("api/emails"))
}

export function create(data){
    const requester = new Requester();

    return requester.post(requester.getUrl(`api/emails`), data)
}

export default {
    index,
    create,
}