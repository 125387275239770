import React, {
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";
import { Project } from "../helpers/Projects";
import { ProjectStoryStatus } from "../helpers/ProjectStoryStatuses";

interface ProjectState {
    project: Project | null;
    setProject: (project: Project|null) => void
    statuses: ProjectStoryStatus[] | null
    setStatuses: (statuses: ProjectStoryStatus[]) => void
}

const ProjectContext = createContext<ProjectState | null>(null);

function useProject(): ProjectState {
    return useContext(ProjectContext) as ProjectState;
}

function ProjectProvider({ children }) {
    //@ts-ignore
    const [project, setProject] = useState<Project | null>(null);
    const [statuses, setStatuses] = useState<ProjectStoryStatus[] | null>(null);

    return (
        <ProjectContext.Provider value={{ project, setProject, statuses, setStatuses}}>
            {children}
        </ProjectContext.Provider>
    );
}

export { ProjectProvider, useProject };
