import React, { useEffect } from "react";
import { useAppState } from "../contexts/AppContext";
import EmailList from "./EmailList";

export default function Emails() {
    const { appState } = useAppState();

    return (
        <div>
            <h1>Emails</h1>
            <EmailList />
        </div>
    );
}
