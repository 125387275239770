import React, { useEffect, useState } from "react";
import Forms, { Form } from "../helpers/Forms";

export default function FormList() {
    const [forms, setForms] = useState<Form[]>([]);

    useEffect(() => {
        Forms.index().then((forms) => {
            setForms(forms);
        });
    }, [setForms]);

    return (
        <div>
            <table className="uk-table uk-table-striped">
                <thead>
                    <tr>
                        <th>Form</th>
                        <th className="uk-text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {forms
                        ? forms.map((form) => (
                              <tr>
                                  <td>{form.name}</td>
                                  <td></td>
                              </tr>
                          ))
                        : null}
                </tbody>
            </table>
        </div>
    );
}
