import React, { useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import Emails from "../components/Emails";
import Forms from "../components/Forms";
import Events from "../components/Events";
import EventNew from "../components/EventNew";
import EventEdit from "../components/EventEdit";
import Projects from "../components/Projects";
import Story from "../components/Story";
import { useAppState } from "../contexts/AppContext";
import Login from "./login";
import Board from "../components/Board";
import Backlog from "../components/Backlog";


export default function App() {
    const {appState} = useAppState()

    if(!appState.user){
        return <Login/>
    }

    return (
        <div
            style={{ minHeight: "100vh" }}
            className="uk-grid-small"
            data-uk-grid
            data-uk-height-viewport="expand: true"
        >
            <div
                style={{ borderRight: "1px solid #e5e5e5" }}
                className="uk-width-medium@m uk-visible@m"
            >
                <div className="uk-padding-small" data-uk-sticky>
                    <ul className="uk-nav uk-nav-default">
                        <li className="uk-nav-header">{appState.title}</li>
                        <li>
                            <Link to="/">Projects</Link>
                        </li>
                        <li className="uk-nav-divider"></li>
                        <li>
                            <a href="#">Item</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="uk-width-expand">
                <div style={{height: '100%'}} className="uk-section uk-section-default uk-padding">
                    <Routes>
                        <Route path="/" element={<Projects />} />
                        <Route
                            path="/projects/:alias/board"
                            element={<Board />}
                        />
                        <Route
                            path="/projects/:alias/backlog"
                            element={<Backlog />}
                        />
                        <Route
                            path="/stories/:alias"
                            element={<Story />}
                        />
                        <Route
                            path="/events"
                            element={<Events />}
                        />
                        <Route
                            path="/events/new"
                            element={<EventNew />}
                        />
                        <Route
                            path="/events/:id"
                            element={<EventEdit />}
                        />
                        <Route
                            path="/emails"
                            element={<Emails />}
                        />
                        <Route path="/forms" element={<Forms />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}
