import React, { useEffect, useState } from "react";
import { useAppState } from "../contexts/AppContext";
import ProjectList from "./ProjectList";
import { Link, useParams } from "react-router-dom";
import Stories, { Story, StoryStatus } from "../helpers/Stories";
import { ReactSortable } from "react-sortablejs";

interface BoardColumnProps{
    allowAddNew?:boolean
    name:string
    statusId:number
    stories: Record<StoryStatus,Story[]>
    setStories?: any
}

export default function BoardColumn({
    allowAddNew = false,
    name,
    statusId,
    stories,
    setStories,
} : BoardColumnProps) {
    const {setAppState} = useAppState()

    const handleNavigateToStory = (story) => {
        setAppState({
            previousPage: {
                name: 'Board',
                link: `/projects/${story.project.shortName.toLocaleLowerCase()}/board`
            }
        })
    }

    const reorderBacklog = (stories: Story[]) => {
        // // if(!project){
        // //     return
        // // }


        const data:{index: number, statusId:number}[] = [];
        stories.forEach((story, index) => {
            story.order = index;
            data[story.id] = {
                index,
                statusId: story.status.id,
            };
            story.status.id = statusId;
        });

        setStories((prev) => {

            // if(!prev){
            //     return {
            //         [statusId]: stories
            //     }
            // }

            if(!prev[statusId]){
                return {
                    ...prev,
                    [statusId]: stories,
                };
            }

            const prevData = {};
            let shouldUpdate = false;

            prev[statusId].forEach((story, index) => {
                prevData[story.id] = {
                    index,
                    statusId: story.status.id,
                };


                if (prevData[story.id].index !== data[story.id]) {
                    shouldUpdate = true;
                }
            });

            if(!shouldUpdate){
                data.forEach((story) => {
    
                    if (story.statusId !== statusId) {
                        shouldUpdate = true;
                    }
                });
            }
            
            if (shouldUpdate) {
                // Stories.reorder(project.shortName, data).then(() => { });
                return {
                    ...prev,
                    [statusId]: stories,
                };
            } else {
                return prev;
            }
        });
    };

    return (
        <div className="">
            <div style={{height: 36}} className="uk-flex uk-flex-between uk-flex-middle">
                <h2 className="uk-h4 uk-margin-remove">{name}</h2>
                {allowAddNew ? (
                    <button className="uk-icon-button uk-button-default"><span data-uk-icon="plus"></span></button>
                ) : null }
                
            </div>
            
            <hr/>

            <ReactSortable
                group='board-column'
                // animation={150}
                // handle=".uk-card-header"
                list={stories[statusId] ?? []}
                setList={reorderBacklog}
                className="space-y uk-padding-small uk-background-muted"
                
            >
                {stories && stories[statusId]?.length > 0 ? stories[statusId].map((story) => (
                    <div key={story.id} className="uk-card uk-card-default uk-card-small">
                        <div className="uk-card-header">
                            <span className="uk-text-muted">{story.fullId}</span>
                            <h5 className="uk-margin-remove"><Link onClick={() => {
                                handleNavigateToStory(story)
                            }} to={`/stories/${story.fullId}`}>{story.name}</Link></h5>
                        </div>
                        <div className="uk-card-body">
                            <label className="uk-label uk-label-default">{story.estimates} SP</label>
                        </div>
                    </div>
                )) : null}

            </ReactSortable>
            
            
        </div>
    );
}
