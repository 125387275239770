import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useAppState } from "../contexts/AppContext";
import Events, { Event } from "../helpers/Events";
import dayjs from "dayjs";

export default function EventEdit() {
    const { appState, setAppState } = useAppState();
    const [event, setEvent] = useState<Event | null>(null);
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        Events.show(id).then((event: Event) => {
            setEvent(event);
        });
    }, []);

    const onSubmit = handleSubmit((values) => {
        console.log(values);
    });

    if (event === null) {
        return null;
    }

    return (
        <div>
            <h1>Your event: {event.name}</h1>
            <form onSubmit={onSubmit}>
                <div className="uk-margin">
                    <label>Name</label>
                    <div className="uk-inline uk-width-1">
                        <input
                            {...register("name")}
                            className="uk-input uk-input-default"
                            placeholder="Name"
                            defaultValue={event.name}
                        />
                    </div>
                </div>
                <div className="uk-margin">
                    <label>Start date</label>
                    <div className="uk-inline uk-width-1">
                        <input
                            {...register("start_at")}
                            className="uk-input uk-input-default"
                            type="date"
                            placeholder="Start date"
                            defaultValue={dayjs(event.start_at).format(
                                "YYYY-MM-DD",
                            )}
                        />
                    </div>
                </div>
                <div className="uk-margin">
                    <label>End date</label>
                    <div className="uk-inline uk-width-1">
                        <input
                            {...register("end_at")}
                            className="uk-input uk-input-default"
                            type="date"
                            placeholder="End date"
                            defaultValue={dayjs(event.end_at).format(
                                "YYYY-MM-DD",
                            )}
                        />
                    </div>
                </div>
                <button className="uk-button uk-button-primary">Create</button>
            </form>
        </div>
    );
}
