import React, { useEffect, useState } from "react";
import Projects, { Project } from "../helpers/Projects";
import { Link, useNavigate } from "react-router-dom";
import { useAppState } from "../contexts/AppContext";
import { useProject } from "../contexts/ProjectContext";

export default function ProjectList() {
    const {setStatuses} = useProject();
    const [projects, setProjects] = useState<Project[]>([]);

    useEffect(() => {
        Projects.index().then((projects) => {
            setProjects(projects);
        });
    }, [setProjects]);


    const handleOnClick = (project: Project) => {
        setStatuses(project.statuses)
    }


    return (
        <div>
            <table className="uk-table uk-table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Short name</th>
                        <th className="uk-text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {projects
                        ? projects.map((project) => (
                              <tr>
                                  <td>{project.name}</td>
                                  <td>{project.shortName}</td>
                                  <td className="uk-text-right">
                                    <Link onClick={() => {
                                        handleOnClick(project)   
                                    }} className="uk-button uk-button-primary" to={`/projects/${project.shortName.toLocaleLowerCase()}/board`}>Board</Link>
                                    <Link onClick={() => {
                                        handleOnClick(project)   
                                    }} className="uk-button uk-button-primary" to={`/projects/${project.shortName.toLocaleLowerCase()}/backlog`}>Backlog</Link>
                                  </td>
                              </tr>
                          ))
                        : null}
                </tbody>
            </table>
        </div>
    );
}
