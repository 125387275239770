import React, { useEffect, useState } from "react";
import { useAppState } from "../contexts/AppContext";
import ProjectList from "./ProjectList";
import { Link, useParams } from "react-router-dom";
import Stories, { Story as StoryType } from "../helpers/Stories";
import BoardColumn from "./BoardColumn";
import ProjectStoryStatuses, { ProjectStoryStatus } from "../helpers/ProjectStoryStatuses";
import { useProject } from "../contexts/ProjectContext";
import Loading from "./Loading";

export default function Story() {
    const {appState} = useAppState()
    const {statuses, setStatuses} = useProject()
    const { alias } = useParams();
    const [story, setStory] = useState<StoryType>();

    useEffect(() => {
        Stories.show(alias).then((story) => {
            setStory(story);
        });
    }, [setStory]);

    useEffect(() => {
        if(statuses === null){
            ProjectStoryStatuses.index(alias).then((statuses) => {
                setStatuses(statuses);
            });
        }
    }, [])

    if(!story){
        return <Loading/>
    }

    return (
        <div style={{height: '100%'}}>
            <div className="space-x">
                {appState.previousPage ? (
                    <>
                        <Link to={appState.previousPage.link}>Back</Link>
                        <span>
                            /
                        </span>
                    </>
                ) : null}
                <span className="uk-text-muted">
                    {story.fullId}
                </span>
            </div>
            <h1 className="uk-margin-remove">{story.name}</h1>

            <div className="space-x flex">
                <label className="uk-label">{story.status.name}</label>
                <label className="uk-label">{story.estimates} SP</label>
            </div>

            <div className="space-y uk-margin-top">
                
                <div>
                    <h4 className="uk-margin-remove">Description</h4>
                    <p className="uk-margin-remove">{story.description}</p>
                </div>

                <div>
                    <h4 className="uk-margin-remove">Requesters</h4>
                    <p className="uk-margin-remove">Volodymyr Tytarenko</p>
                </div>

                <div>
                    <h4 className="uk-margin-remove">Owners</h4>
                    <p className="uk-margin-remove">Volodymyr Tytarenko</p>
                </div>
            </div>
        </div>
    );
}
