import Requester from "./Requester";

export interface Form {
    id: number
    name: string
    active: boolean
}

export function index(){
    const requester = new Requester();

    return requester.get(requester.getUrl("api/forms"))
}

export function create(data){
    const requester = new Requester();

    return requester.post(requester.getUrl(`api/forms`), data)
}

export default {
    index,
    create,
}