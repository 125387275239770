import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../contexts/AppContext";
import Events from "../helpers/Events";

export default function EventNew() {
    const { appState, setAppState } = useAppState();
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();

    const onSubmit = handleSubmit((values) => {
        Events.create(values).then(() => {
            navigate("/events");
        });
    });

    return (
        <div>
            <h1>Create a new event</h1>
            <form onSubmit={onSubmit}>
                <div className="uk-margin">
                    <label>Name</label>
                    <div className="uk-inline uk-width-1">
                        <input
                            {...register("name")}
                            className="uk-input uk-input-default"
                            placeholder="Name"
                        />
                    </div>
                </div>
                <div className="uk-margin">
                    <label>Start date</label>
                    <div className="uk-inline uk-width-1">
                        <input
                            {...register("start_at")}
                            className="uk-input uk-input-default"
                            type="date"
                            placeholder="Start date"
                        />
                    </div>
                </div>
                <div className="uk-margin">
                    <label>End date</label>
                    <div className="uk-inline uk-width-1">
                        <input
                            {...register("end_at")}
                            className="uk-input uk-input-default"
                            type="date"
                            placeholder="End date"
                        />
                    </div>
                </div>
                <button className="uk-button uk-button-primary">Create</button>
            </form>
        </div>
    );
}
