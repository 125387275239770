
export default class Requester {

    constructor() {
        this.token = localStorage.getItem('token') ?? null;
    }

    setToken(token){
        this.token = token;
    }

    getUrl(action, query){
        let url = 'https://tracker.titarlab.com/'+action;
        url = this.addQuery(url, query);
        return url;
    }

    get(url, options = null){
        return new Promise((resolve, reject) => {
            this.request(url,'get',options).then((data) => {
                resolve(data);
            }).catch((err) => {
                reject(err)
            })
        })
    }

    post(url, body, options = {}){
        options = {
            ...options,
            body:JSON.stringify(body)
        };
        return new Promise((resolve, reject) => {
            this.request(url,'post',options).then((data) => {
                resolve(data);
            }).catch((err) => {
                reject(err)
            })
        })
    }

    put(url, body, options = null){
        options = {
            ...options,
            body:JSON.stringify(body)
        };
        return new Promise((resolve, reject) => {
            this.request(url,'put',options).then((data) => {
                resolve(data);
            }).catch((err) => {
                reject(err)
            })
        })
    }

    delete(url, options = null){
        options = {
            ...options,
        };
        return new Promise((resolve, reject) => {
            this.request(url,'delete',options).then((data) => {
                resolve(data);
            }).catch((err) => {
                reject(err)
            })
        })
    }

    request(url, method, options = null){
        method = method.toLowerCase();
        let headers = new Headers();

        if(this.token != null){
            headers = new Headers({
                "Authorization":"Bearer "+this.token,
            });
        }

        headers.set('Content-type', 'application/json')


        return new Promise((resolve, reject) => {
            fetch(url,{
                ...options,
                method:method,
                headers:headers
            }).then((response) => {
                resolve(response.json());
            }).catch((err) => {
                reject(err);
            })
        })
    }

    addQuery(url, query){
        if(query != null && query.length > 0){
            url += "?";
            query.forEach((queryItem, index) => {
                url += queryItem.name+"="+queryItem.value;
                if(index != query.length-1){
                    url += "&";
                }
            })
        }
        return url;
    }

}
