import { ProjectStoryStatus } from "./ProjectStoryStatuses";
import Requester from "./Requester";

export interface Project {
    id: number
    name: string
    vendorId: number
    shortName: string
    statuses: ProjectStoryStatus[]
}

export function index(){
    const requester = new Requester();

    return requester.get(requester.getUrl("api/projects"))
}

export function destroy(id){
    const requester = new Requester();

    return requester.delete(requester.getUrl(`api/projects/${id}`))
}

export function create(data){
    const requester = new Requester();

    return requester.post(requester.getUrl(`api/projects`), data)
}

export function update(id, data){
    const requester = new Requester();

    return requester.put(requester.getUrl(`api/projects/${id}`), {
        
    })
}

export default {
    index,
    create,
    destroy,
    update
}