import React, { useEffect, useState } from "react";
import Emails, { Email } from "../helpers/Emails";

export default function EmailList() {
    const [emails, setEmails] = useState<Email[]>([]);

    useEffect(() => {
        Emails.index().then((emails) => {
            setEmails(emails);
        });
    }, [setEmails]);

    return (
        <div>
            <table className="uk-table uk-table-striped">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Status</th>
                        <th className="uk-text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {emails
                        ? emails.map((email) => (
                              <tr>
                                  <td>{email.email}</td>
                                  <td>{email.status}</td>
                                  <td></td>
                              </tr>
                          ))
                        : null}
                </tbody>
            </table>
        </div>
    );
}
