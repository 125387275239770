import Requester from "./Requester";

export interface ProjectStoryStatus {
    id: number
    name: string
    order: number
}

export function index(projectId){
    const requester = new Requester();

    return requester.get(requester.getUrl(`api/projects/${projectId}/statuses`))
}


export default {
    index,
}