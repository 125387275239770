import React, { useEffect, useState } from "react";
import EventList from "./EventList";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

export default function Events() {
    const navigate = useNavigate();

    const goToNew = () => {
        navigate("/events/new");
    };

    return (
        <div>
            <div className="uk-flex uk-flex-between uk-flex-middle">
                <div>
                    <h1 className="uk-margin-remove">Events</h1>
                </div>
                <div>
                    <Button onClick={goToNew} color="primary">
                        New
                    </Button>
                </div>
            </div>
            <EventList />
        </div>
    );
}
