import React from "react";
import { BrowserRouter} from "react-router-dom";
import ReactDOM from "react-dom/client";
import { AppProvider } from "./contexts/AppContext";

import App from "./layouts/app";
import { ProjectProvider } from "./contexts/ProjectContext";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppProvider>
                <ProjectProvider>
                    <App/>
                </ProjectProvider>
            </AppProvider>
        </BrowserRouter>
    </React.StrictMode>,
);
