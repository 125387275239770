import { Project } from "./Projects";
import { ProjectStoryStatus } from "./ProjectStoryStatuses";
import Requester from "./Requester";

export interface Story {
    id: number
    name: string
    description: string
    status: ProjectStoryStatus
    project: Project
    vendorId: number
    estimates: number | null
    fullId: string
    order: number
}

export enum StoryStatus {
    Open = 1,
    InProgress = 2,
    Demo = 3,
    Qa = 4,
    Done = 5,
}

export function index(projectId){
    const requester = new Requester();

    return requester.get(requester.getUrl(`api/projects/${projectId}/stories`))
}

export function indexByStatus(projectId){
    const requester = new Requester();

    return requester.get(requester.getUrl(`api/projects/${projectId}/stories?groupBy=status`))
}

export function show(alias){
    const requester = new Requester();

    return requester.get(requester.getUrl(`api/stories/${alias}`))
}

export function destroy(projectId, id){
    const requester = new Requester();

    return requester.delete(requester.getUrl(`api/projects/${projectId}/stories/${id}`))
}

export function create(projectId, data){
    const requester = new Requester();

    return requester.post(requester.getUrl(`api/projects/${projectId}/stories`), data)
}

export function update(projectId, id, data){
    const requester = new Requester();

    return requester.put(requester.getUrl(`api/projects/${projectId}/stories/${id}`), {
        
    })
}

export function reorder(projectId: string, data: Record<string, string>){
    const requester = new Requester();

    return requester.post(requester.getUrl(`api/projects/${projectId}/reorder-stories`), {
        order: data
    })
}

export default {
    index,
    indexByStatus,
    show,
    create,
    destroy,
    update,
    reorder,
}