import React, { ReactNode } from "react";

interface ButtonProps {
    children?: ReactNode;
    color?: "default" | "primary" | "secondary";
    onClick?: () => any;
}

export default function Button({
    children,
    onClick = () => {},
    color = "default",
}: ButtonProps) {
    return (
        <button onClick={onClick} className={`uk-button uk-button-${color}`}>
            {children}
        </button>
    );
}
